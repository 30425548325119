import React from 'react'

const Skeleton = () => {
  return (
    <div className='skeleton'>
      <h1>GROOVE FUSION</h1>
      <hr />
      <h2>BATTLE</h2>
    </div>
  )
}

export default Skeleton
