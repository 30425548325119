import React from 'react'
// import Header from './components/Header'
import Skeleton from './components/Skeleton'
function App () {
  return (
    <div className="App">
      {/* <Header></Header> */}
      <div className="skeletonApp">
        <Skeleton></Skeleton>
      </div>
    </div>
  )
}

export default App
